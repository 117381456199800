.dashboard-grid {
	margin: 16px;
	max-width: 100%;

	&__sizer,
	&__item {
		width: calc(50% - 12px);
	}

	&__item {
		margin-bottom: 24px;
	}
}
