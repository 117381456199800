.dashboard-detail {
	$root: &;
	background: var(--mdc-theme-surface);
	margin: 16px;
	padding: 15px;

	&__supertitle {
		font-size: 14px;
		margin-bottom: 20px;
		display: inline-block;
	}

	&__subtitle {
		font-weight: 500;
		display: block;
		margin-bottom: 20px;
	}
	&__title {
		margin: 0 0 10px 0;
	}

	&__info-grid {
		display: grid;
		grid-gap: 20px;
		grid-template-columns: 1fr 1fr 1fr 1fr 600px 1fr;
	}

	&__info-pair {
		h4 {
			font-weight: normal;
			margin: 0;
		}

		span {
			font-weight: bold;
			display: block;
			margin-bottom: 8px;
		}

		&--prominent {
			justify-self: end;
			text-align: right;
			span {
				font-size: 24px;
			}
		}
	}

	&__grid-spacer {
	}

	& hr {
		color: var(--mdc-theme-primary);
		margin: 20px 0 30px 0;
	}
}
