.mdc-data-table {
	margin-top: 15px;
	width: 100%;
	max-width: 100%;
	border: none;

	&__header-row,
	&__row {
		border-color: var(--mdc-theme-primary);
	}

	&__header-row {
		border-bottom: 2px solid;
		height: 40px;
	}

	&__sort-icon {
		cursor: pointer;
		color: rgba(black, 0.8);
		transform: translate(7px, 2px);
		position: absolute;
		font-size: 18px;
		line-height: 18px;

		&:hover {
			color: rgba(black, 1);
		}
	}

	&__cell {
		white-space: normal;

		&--pagination {
			text-align: right;
			span {
				margin-right: 25px;
				font-weight: bold;
			}

			& .material-icons {
				font-size: 20px;
				transform: translateY(4px);
			}
		}
	}

	&__previous-button,
	&__next-button {
		text-decoration: none;
		color: rgba(0, 0, 0, 0.87);
		margin-left: 15px;
	}

	&__row {
		&--loader {
			td {
				text-align: center;
			}
		}
	}
}
