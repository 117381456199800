.mdc-drawer {
	background-color: $db-background;

	&__header {
		display: flex;
		align-items: center;
		margin: 30px 0 50px 0;
		& > svg {
			height: 100%;
		}
	}

	& .mdc-list {
		&-item {
			color: var(--mdc-theme-primary);

			&__graphic {
				color: inherit !important;
			}

			&:hover {
				& > span {
					text-decoration: underline;
				}
			}

			&--activated {
				background: var(--mdc-theme-primary);
				color: var(--mdc-theme-on-primary);
			}
		}
	}
}
