.dashboard-panel {
	@extend %surface;

	&__heading {
		margin: 0;
		font-weight: bold;
	}

	&__info {
		margin: 15px 0;
	}
}
