.mdc-top-app-bar {
	transition: background 0.3s;
	&__section {
		transition: opacity 0.2s;
	}

	&.search-active {
		background-color: var(--mdc-theme-surface);
		.mdc-top-app-bar__section {
			opacity: 0;
			pointer-events: none;
		}
		.dashboard-search {
			opacity: 1;
			z-index: 5;
			pointer-events: all;

			&__label {
				top: 17px;
			}
		}
	}
}

.dashboard-search {
	transition: opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1);
	z-index: 1;
	opacity: 0;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	font-size: 24px;
	pointer-events: none;

	&__form,
	&__input {
		width: 100%;
		height: 100%;
	}

	&__input {
		padding: 0 72px 0 40px;
		top: 16px;
		font-size: 24px;
		background: var(--mdc-theme-surface);
		appearance: none;
		outline: 0;
		border: 0;
		position: relative;
		z-index: 5;
		top: 0;
	}

	&__label {
		transition: top 0.3s 0.3s;
		position: absolute;
		left: 48px;
		top: -24px;
		color: var(--mdc-theme-primary);
		z-index: 6;

		&--hide {
			opacity: 0;
		}
	}

	&__close {
		position: absolute;
		color: var(--mdc-theme-primary);
		right: 17px;
		top: 8px;
		z-index: 6;
	}

	&__results {
		position: absolute;
		top: 64px;
		width: 100%;
		z-index: 4;
		background: var(--mdc-theme-surface);
		color: black;
		box-shadow:
			0 2px 4px -1px rgba(0, 0, 0, 0.2),
			0 4px 5px 0 rgba(0, 0, 0, 0.14),
			0 1px 10px 0 rgba(0, 0, 0, 0.12);
	}

	&__line {
		height: 1px;
		width: calc(100% - 72px);
		margin-left: 40px;
		background: $db-background;
	}

	&__list {
		padding: 20px 72px 20px 40px;
	}

	&__item {
		& + & {
			margin-top: 12px;
		}
	}
}
