.mdc-chip {
	$chip: &;
	border: 1px solid black;

	&__hidden-input {
		opacity: 0;
		position: absolute;
		widows: 0px;
		height: 0px;
		pointer-events: none;
	}

	&__icon {
		display: none;
	}

	&-set--choice {
		#{$chip}--selected {
			background-color: black;
			& #{$chip}__icon {
				color: var(--mdc-theme-primary) !important;
				display: inline-block;
			}
		}
	}
}
