$bp-small: 768;
$bp-medium: 1024;
$bp-large: 1280;
$bp-very-large: 1400;
$db-background: #221e20;

@import "../../global/scss/reset";

@import "mixins/mixin-loader";
@import "mixins/mdc";

@import "layouts/dashboard-detail";
@import "layouts/dashboard-listing";
@import "layouts/dashboard-login";

@import "components/dashboard-grid";
@import "components/dashboard-panel";
@import "components/dashboard-search";
@import "components/mdc/mdc-data-table";
@import "components/mdc/mdc-top-app-bar";
@import "components/mdc/mdc-chip";
@import "components/mdc/mdc-drawer";
@import "components/mdc/mdc-tab";

:root {
	--mdc-theme-primary: #9d9187;
	--mdc-theme-surface: #eceae8;
	--mdc-theme-on-primary: $db-background;
}

.dashboard {
	padding-top: 64px;
	background-color: $db-background;
	min-height: 100vh;
}

.mdc-list-item__text {
	text-decoration: none;
}

.mdc-dialog--overflow {
	.mdc-dialog__content,
	.mdc-dialog__surface {
		overflow: visible;
	}
}

.mdc-text-field {
	&__error {
		color: red;
	}
}

a {
	display: inline-block;
	text-decoration: underline;
}

* + .mdc-button {
	margin-top: 20px;
	display: block;
}

.btn_export_close {
	position: absolute;
	top: 3%;
	right: 2%;
}
