.dashboard-login {
	width: 100%;
	height: calc(100vh - 64px);
	display: flex;
	align-content: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__logo {
		background: $db-background;
		position: absolute;
		left: 20px;
		top: 20px;
		width: 220px;
	}

	&__big-text {
		display: block;
		font-size: 86px;
		margin: 0 20px 20px 20px;
		color: var(--mdc-theme-primary);
		width: 80%;
	}

	&__form {
		width: 80%;
		position: relative;
		display: flex;
		flex-direction: column;
		background-color: var(--mdc-theme-surface) !important;
		padding: 20px;
		border-radius: 4px;

		& > * + * {
			margin-top: 20px;
		}

		& > button {
			width: 150px;
		}
	}

	&__forgot-pw {
		position: absolute;
		right: 20px;
		bottom: 20px;
	}
}
