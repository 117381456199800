$topbar-ink: var(--mdc-theme-primary);

.mdc-top-app-bar {
	box-shadow:
		0 2px 4px -1px rgba(0, 0, 0, 0.2),
		0 4px 5px 0 rgba(0, 0, 0, 0.14),
		0 1px 10px 0 rgba(0, 0, 0, 0.12);
	color: $topbar-ink;
	background-color: #1c191a;

	&__navigation-icon,
	&__action-item {
		color: $topbar-ink !important;
	}
}
