.mdc-tab-bar {
	margin: 0 16px;
	width: auto;
	background-color: var(--mdc-theme-surface);
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	& + .dashboard-listing {
		margin-top: 0;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

.mdc-tab {
	&__text-label {
		color: black !important;
	}
}
