.dashboard-listing {
	@extend %surface;
	margin: 16px;
	position: relative;

	&__title {
		margin: 0;
	}

	&__intro {
		margin: 15px 0 30px 0;
	}

	&__filter {
		margin-top: 15px;
		display: flex;
		flex-direction: row;
		align-items: center;

		> * {
			margin-right: 30px;
		}

		> button[type="submit"] {
			margin: 0;
		}
	}

	&:after {
		transition: opacity 150ms ease-in;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 5;
		background-color: rgba(black, 0.7);
		content: "";
	}

	&.navigating-away {
		cursor: wait;
		&:after {
			opacity: 1;
		}
	}
}
